import React, { useState, useRef } from "react";
import { Formik, Field } from "formik";
import Spinner from "../../components/spinner";
import * as Yup from "yup";
import cogoToast from "cogo-toast";
import AXIOS from "../../../axios-config";
import ReCAPTCHA from "react-google-recaptcha";
import settings from "../../../settings";
import { Link } from "gatsby";

const SchoolCardForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  const initialValues = {
    fullname: "",
    email: "",
    otherSelectedTrackName: "",
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string()
      .required("Please enter your first name and last name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Your email address is incorrect"
      ),
    selectedTracks: Yup.array().required(
      "Please select at least one of the location"
    ),
    otherSelectedTrackName: Yup.string()
    .min(1, "Must have a character")
    .max(255, "Must be shorter than 255"),
  });

  const handleTextFieldDisplay = () => {
    const currentState = show;
    setShow(!currentState);
  }

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setShow(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };
    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }

    AXIOS.post("/api/mschool-ontour-form", values, {headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center",
        });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center",
        });
      });
  };

  // const setSubHeader = (props) => {

    const { title, text, subtitle } = props;
  //   let sub_header;
  //   if (subtitle) {
  //     sub_header = (
  //       <div className="school-license-card__model__sub__header__div">
  //         <h3 className="school-license-card__model__sub__header__title">
  //           <b>{subtitle}</b>
  //         </h3>
  //       </div>
  //     );
  //   }
  // }

    return (
      <div className="school-license-card__model__item">
        <div className="school-license-card__model_form">
          <div className="school-license-card__model__header">
            <div className="school-license-card__model__header__div">
              <h4 className="school-license-card__model__header__title">
                {title}
              </h4>
            </div>
            {subtitle ? <div className="school-license-card__model__sub__header__div">
                        <h3 className="school-license-card__model__sub__header__title">{subtitle}</h3>
            </div>:''}
            <br />
          </div>
          <div className="school-license-card__model__content">
            <p>{text}</p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="customForm">
                <div className="experience-form">
                  <div className="input__group">
                    <Field
                      type="text"
                      placeholder="First and Last Name*"
                      name="fullname"
                      className={
                        touched.fullname && errors.fullname ? "has-error" : null
                      }
                    />
                    {errors.fullname && touched.fullname ? (
                      <span className="input-feedback">{errors.fullname}</span>
                    ) : (
                      <span className="input-feedback"></span>
                    )}
                  </div>
                  <div className="input__group">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email*"
                      className={
                        touched.email && errors.email ? "has-error" : null
                      }
                    />
                    {errors.email && touched.email ? (
                      <span className="input-feedback">{errors.email}</span>
                    ) : (
                      <span className="input-feedback"></span>
                    )}
                  </div>
                  <div className="input__group">
                    <Field
                      type="text"
                      name="otherSelectedTrackName"
                      placeholder="Enter The Track Name Here*"
                      hidden={!show}
                      required={show}
                      className={
                        touched.otherSelectedTrackName && errors.otherSelectedTrackName ? "has-error" : null
                      }
                    />
                    {errors.otherSelectedTrackName && touched.otherSelectedTrackName ? (
                      <span className="input-feedback">{errors.otherSelectedTrackName}</span>
                    ) : (
                      <span className="input-feedback"></span>
                    )}
                  </div>
                  <div className="checkbox_on_tour">
                    <p>Select Tracks* (must choose at least one)</p>
                    <div className="row">
                      <div className="col">
                        <div className="checkbox_on_tour__group">
                          <Field
                            type="checkbox"
                            name="selectedTracks"
                            id="virginia_international_raceway"
                            value="Virginia International Raceway"
                          />
                          <label htmlFor="virginia_international_raceway">
                            Virginia International Raceway
                          </label>
                        </div>
                        <div className="checkbox__group">
                          <Field
                            type="checkbox"
                            name="selectedTracks"
                            id="indianapolis_motor_speedway"
                            value="Indianapolis Motor Speedway"
                          />
                          <label htmlFor="indianapolis_motor_speedway">
                            Indianapolis Motor Speedway
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="checkbox__group">
                          <Field
                            type="checkbox"
                            name="selectedTracks"
                            id="other"
                            value="Other"
                            onClick={handleTextFieldDisplay}
                          />
                          <label htmlFor="other">Other</label>
                        </div>
                      </div>
                    </div>

                    {errors.selectedTracks && touched.selectedTracks ? (
                      <span className="input-feedback">
                        {errors.selectedTracks}
                      </span>
                    ) : (
                      <span className="input-feedback"></span>
                    )}
                  </div>
                  <div>
                    <p className="legal_note">We respect your privacy. When you submit your contact information, we agree to only contact you in accordance with our <Link to="https://www.bmwusa.com/privacy-policy/index.html" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.</p>
                  </div>
                  <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/>
                  <button
                    className="btn-form"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isLoading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
}

export default SchoolCardForm;
